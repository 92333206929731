import React from "react";
import { CardBody, Container, Card, Row, Col } from "react-bootstrap";
import '../App.css';

class EpsFooter extends React.Component {
    render() {
        return (
            <Container className="mt-auto">
                <Card className="page-card top-2">
                    <CardBody>
                        <Row className="justify-content-center align-items-center">
                            <Col className="text-center" md="4">
                                <p>© 2023 EPSILON PLIUS. All rights reserved.</p>
                            </Col>
                            <Col>
                                <p><a className="footer-link" href="/privacypolicy">Privatumo politika</a></p>
                            </Col>
                            <Col>
                                <p><a className="footer-link" href="/pgp/warrant-canary.txt">Warrant Canary</a></p>
                            </Col>
                            <Col>
                                <p><a className="footer-link" href="/pgp/epsilon_plius_pgp.txt">PGP</a></p>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        );
    }
}

export default EpsFooter;