import React from "react";
import { Container, Row, Col, Card, CardBody, CardHeader, Button } from 'react-bootstrap';
import TopNavbar from "../Components/TopNavbar";
import EpsFooter from "../Components/EpsFooter";
import ReactMarkdown from 'react-markdown'

class Portfolio extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            projects: [
                {
                    name: "SANDIS",
                    shortdescription: "SANDIS - sandelio valdymo sistema",
                    description: "Paprasta ir patogi sandelio valdymo sistema skirta gamybos sandelio valdymui apjungianti tokias galimybes kaip:\r\n\r\n-   I\u0161samios ataskaitos pagal detales, komplektus, liku\u010Dius ir kt.\r\n-   Klient\u0173 ry\u0161iu ir u\u017Esakym\u0173 valdymas\r\n-   S\u0105skait\u0173-fakt\u016Br\u0173 ir va\u017Etara\u0161\u010Di\u0173 formavimas\r\n-   Pakrovimo ir paskirstymo po transporto priemones valdymas\r\n-   Galim\u0173 sudaryti komplekt\u0173 (gamini\u0173), bei tr\u016Bkstamo iki reikiamo kiekio detali\u0173 kiekio apskai\u010Diavimas",
                    image: "/img/sandis_repr.jpg",
                    linkurl: ""
                },
                {
                    name: "Achroma",
                    shortdescription: "Achroma.lt - suasmenintų suvenirų parduotuvė",
                    description: "Elektronin\u0117 parduotuv\u0117 siulanti u\u017Esakyti suasmenintus pakabukus ir mar\u0161kin\u0117lius su mylim\u0173 augintini\u0173 atvaizdu.\r\n\r\n- Paskirtis: \r\nElektronin\u0117 parduotuv\u0117 siulanti u\u017Esakyti suasmenintus pakabukus ir mar\u0161kin\u0117lius su mylim\u0173 augintini\u0173 atvaizdu.\r\n- Realizuoti sprendimai:\r\n\t- Nuotolini\u0173 kurs\u0173 atvaizdavimas\r\n\t- Naudotojo registracija\r\n\t- Apmok\u0117jimas\r\n\t- Naudotoj\u0173 ir kurs\u0173 valdymas\r\n\t- Kurs\u0173 vaizdo \u012Fra\u0161\u0173 atvaizdavimas\r\n\t- Apmok\u0117jimas per pasirinkt\u0105 bank\u0105 bei mok\u0117jimo kortel\u0117mis\r\n\t- Naudotojo pasirinktos nuotraukos \u012Fk\u0117limas u\u017Esakant prek\u0119\r\n- Panaudotos technologijos:\r\n\t- Wordpress\r\n\t- WooCommerce\r\n\t- PHP",
                    image: "/img/achroma_repr.jpg",
                    linkurl: "https://achroma.lt"
                },
                {
                    name: "VITALIS VASYLIUS Photography",
                    shortdescription: "Vitalis Vasylius Photography is a photography business that showcases photographs taken by Vitalis Vasylius.",
                    description: "Vitalis Vasylius Photography is a photography business that showcases photographs taken by Vitalis Vasylius. The photographs depict various locations, including La Palma, Faroe, Portugal, Chile, Madeira, and the Azores.",
                    image: "/img/vitalis_repr.jpg",
                    linkurl: "https://www.vitalisvasylius.photography/"
                }
            ]
        };
    }

    render() {

        const pageStyle = {
            backgroundImage: "url(background_hd.jpg)",
            height: '100vh',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            color: "white",
            overflowY: "scroll"
        };

        return (
            <Container fluid style={pageStyle}>
                <TopNavbar />
                <Container>
                    <Row>
                        {this.state.projects.map(project => (
                            <Row style={{marginTop:"2vh"}}>
                                <Card className="page-card">
                                    <CardHeader>
                                        <h3>{project.shortdescription}</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col md="6">
                                                <ReactMarkdown>{project.description}</ReactMarkdown>
                                            </Col>
                                            <Col>
                                                <img src={project.image} style={{width:"100%"}} alt={project.name} />
                                            </Col>
                                        </Row>
                                        <Row style={{marginTop:"2vh"}}>
                                            <a href={project.linkurl} target="_blank" rel="noopener noreferrer">
                                                <span style={{color:"white"}}>{project.linkurl}</span>
                                            </a>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Row>
                        ))}
                    </Row>
                <EpsFooter />
                </Container>
            </Container>
        );
    }
}

export default Portfolio;