import './App.css';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import Home from "./Pages/Home";
import Portfolio from "./Pages/Portfolio";
import Kontaktai from './Pages/Kontaktai';
import PrivacyPolicy from './Pages/PrivacyPolicy';

function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />
    },
    {
      path: "/portfolio",
      element: <Portfolio />
    },
    {
      path: "/kontaktai",
      element: <Kontaktai />
    },
    {
      path: "/privacypolicy",
      element: <PrivacyPolicy />
    }
  ]);
  
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
