import React from "react";
import { Container, Card, Row, Col, CardHeader, CardBody, Button, Form } from 'react-bootstrap';
import TopNavbar from "../Components/TopNavbar";
import EpsFooter from "../Components/EpsFooter";
import axios from "axios";

class Kontaktai extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email:"",
            notes:""
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        const data = {
            email: this.state.email,
            notes: this.state.notes,
            name: this.state.email,
            service: "-"
        }

        axios.post('/Form.php', data)
            .then(response => {
                
            })
            .catch(error => {

            });
    }


    render() {

        const pageStyle = {
            backgroundImage: "url(background_hd.jpg)",
            height: '100vh',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            color: "white"
        };

        return (
            <Container fluid style={pageStyle}>
                <TopNavbar />
                <Container className="main-container">
                    <Row>
                        <Col md="6" xs="12">
                            <Card className="page-card top-2">
                                <CardHeader>
                                    <h3>Susisiekite su mumis</h3>
                                </CardHeader>
                                <CardBody>
                                    <Form onSubmit={this.handleSubmit}>
                                        <Form.Group className="mb-3" controlId="contactEmail">
                                            <Form.Label>El. paštas*</Form.Label>
                                            <Form.Control type="email" placeholder="El. paštas" required name="email" onChange={this.handleInputChange}
                                            value={this.state.email}/>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="contactMessage">
                                            <Form.Label>Žinutė</Form.Label>
                                            <Form.Control as="textarea" rows={3} name="notes" onChange={this.handleInputChange}
                                            value={this.state.notes}/>
                                        </Form.Group>
                                        <Button type="submit" variant="outline-light">Siųsti</Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="6" xs="12">
                            <Card className="page-card top-2">
                                <CardHeader>
                                    <h3>Rekvizitai</h3>
                                </CardHeader>
                                <CardBody>
                                    <h5>UAB EPSILON PLIUS</h5>
                                    <p>Į.k. 302420002</p>
                                    <p>V. Nagevičiaus g. 3, LT-08237 Vilnius</p>
                                    <img src="/img/tel_nr.png" alt="+370&nbsp;633&nbsp;44&nbsp;128" />

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <EpsFooter />
            </Container>
        );
    }
}

export default Kontaktai;