import React, { useEffect } from "react";

function HubspotContactForm() {

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "//js-eu1.hsforms.net/forms/embed/v2.js";
        document.body.appendChild(script);
        script.addEventListener('load', () => {

            if (window.hbspt) {
                window.hbspt.forms.create({
                    region: "eu1",
                    portalId: "143749165",
                    formId: "72418d2e-3b5a-42df-9cdc-7933ccd0b827",
                    target: "#contactForm"
                });
            }
        });
    },[]);

    return (
        <div>
            <div id="contactForm"></div>
        </div>
    );


}

export default HubspotContactForm;