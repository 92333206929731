import React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import '../App.css';

class TopNavbar extends React.Component {
    render() {
        return (
            <Container>
                <Navbar expand="lg" data-bs-theme="dark" >
                    <Container>
                        <Navbar.Brand href="/">
                            <img
                                src="epsilon_logo_shadow.png"
                                width="90"
                                height="90"
                                className="d-inline-block align-top"
                                alt="EPSILON PLIUS"
                            />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link href="/">Paslaugos</Nav.Link>
                                <Nav.Link href="/portfolio">Portfolio</Nav.Link>
                                <Nav.Link href="/kontaktai">Kontaktai</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </Container>
        );
    }
}

export default TopNavbar;